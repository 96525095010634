<template>
  <v-container fluid>
    <v-card flat>
      <v-card-title>
        <strong><h2>Cadastro</h2></strong>
      </v-card-title>
    </v-card>

    <v-tabs v-model="tab">
      <v-tab>Pacientes</v-tab>
      <v-tab>Equipe</v-tab>
      <v-tab>Operadoras</v-tab>
      <v-tab>Complementar</v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">        
      <v-tab-item>
        <Pacientes />
      </v-tab-item>
      <v-tab-item>
        <EquipeMultidisciplinar />
      </v-tab-item>
      <v-tab-item>
        <Operadoras />
      </v-tab-item>
      <v-tab-item>
        <Complementar />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>
<script>
export default {
  name:'Contas',
  components:{
    // TrocaSenha:()=>import('./TrocaSenha.vue'),
    // Tab:()=>import('./Tab.vue'),
    Pacientes:()=>import('./Pacientes.vue'),
    Operadoras:()=>import('./Operadoras.vue'),
    EquipeMultidisciplinar:()=>import('./EquipeMultidisciplinar.vue'),
    Complementar:()=>import('./Complementar.vue'),
  },
  data:()=>({
    tab:0,
  }),
  methods:{
   
  },computed:{

  },mounted(){
  }

}
</script>